<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.templates') }}</h1>
        <FiltersBar
            ref="filtersBar"
            @search="search = $event"
        ></FiltersBar>
        <TemplatesConfigurationsTable @change-page="changePage($event)"
                                      @change-amount="changeAmount($event)"
                                      @sort="sortingColumn = $event"
                                      @fetch="fetchTemplates"
                                      @set-archive-mode="changeArchiveMode($event)"
                                      :loader="loader"
                                      :changed-page="pagination.page"></TemplatesConfigurationsTable>

        <ManageTemplate @fetch="fetchTemplates"></ManageTemplate>
        <ManageQuestion></ManageQuestion>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapFields } from 'vuex-map-fields';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import TemplatesConfigurationsTable from '@/components/templatesConfigurations/TemplatesConfigurationsTable';
import ManageTemplate from '@/components/templatesConfigurations/manageTemplate/ManageTemplate';
import ManageQuestion from '@/components/templatesConfigurations/manageQuestion/ManageQuestion';
import { mapState } from 'vuex';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import filtering from '@/mixins/filtering';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'TemplatesConfigurations',
    components: { FiltersBar, TemplatesConfigurationsTable, ManageTemplate, ManageQuestion },
    mixins: [
        filtering,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        paginationSettings
    ],
    data: () => ({
        loader: false,
        pagination: {
            page: 1,
            amount: 20
        },
        sortingColumn: null,
        debouncedFetchTemplates: null,
        archiveFilter: false,
        currentFilter: true
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customers'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.sites'),
                    loading: false,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE,
                    referralCallback: this.referralSiteCallback,
                    lazy: this.getSitesLazyLoading,
                    searchCallback: this.debouncedGetSites,
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.pagination.page = 1;
                this.fetchTemplates();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.debouncedFetchTemplates();
                }
            }
        }
    },
    methods: {
        generateFilters () {
            const params = {};
            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if (!this.filters[f]) return;

                if (Array.isArray(this.filters[f]) && this.filters[f].length > 0) {
                    params[`filter[${f}`] = this.filters[f].join(',');
                } else {
                    params[`filter[${f}`] = this.filters[f];
                }
            });
            return params;
        },
        fetchTemplates () {
            this.loader = true;

            const params = {
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                'filter[archive]': this.archiveFilter,
                'filter[current]': this.currentFilter,
                'extra_fields[templates]': 'customers_ids,places_ids,points_ids',
                ...this.generateFilters()
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.search) {
                params['filter[search][match]'] = this.search;
            }

            this.$store.dispatch('templates/getTemplates', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchTemplates();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchTemplates();
        },
        changeArchiveMode (event) {
            this.archiveFilter = event;
            this.pagination.page = 1;
            this.fetchTemplates();
        }
    },
    created () {
        this.debouncedFetchTemplates = debounce(this.fetchTemplates, 1000);
    },
    mounted () {
        this.setFiltersWatcher(this.debouncedFetchTemplates);
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.TEMPLATE)) {
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('locations/getLocations')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.LOCATION_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('customers/getCustomers')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.CUSTOMER_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.fetchTemplates();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
